import React, { Component } from "react";
import { textFilter } from "react-bootstrap-table2-filter";
import { Col, Container, Row } from "reactstrap";

import AsyncTable from "./AsyncTable.js";
import { citadelDef, serviceDef } from "./FuelTables.js";

function makeReport(data) {
  const svc = {};
  for (const cit of data) {
    if (
      cit.gsf_data.rental_state !== null &&
      cit.gsf_data.rental_state !== "NOT_RENTED"
    ) {
      continue;
    }
    for (const sv of cit.services) {
      if (!sv.online) {
        continue;
      }
      let name;
      if (sv.name === "Blueprint Copying") {
        name = "Research";
      } else if (
        sv.name === "Material Efficiency Research" ||
        sv.name === "Time Efficiency Research"
      ) {
        continue;
      } else {
        name = sv.name;
      }
      const service = serviceDef[name];
      const multiplier = citadelDef[cit.type.type_id][service.kind];
      if (multiplier !== 1) {
        name = name + citadelDef[cit.type.type_id].name;
      }
      if (!(name in svc)) {
        svc[name] = {
          count: 0,
          cost: 0,
          stat: Math.ceil(service.consumption * multiplier),
        };
      }
      svc[name].count++;
      svc[name].cost += Math.ceil(service.consumption * multiplier);
    }
  }
  const results = [];
  for (const key in svc) {
    const rate = svc[key];
    results.push({
      moduleKind: key,
      staticRate: rate.stat,
      numOnline: rate.count,
      hourlyBurn: rate.cost,
      weeklyBurn: rate.cost * 24 * 7,
      monthlyBurn: rate.cost * 24 * 30,
    });
  }
  results.push(
    results.reduce(
      function (c, v) {
        [
          "staticRate",
          "numOnline",
          "hourlyBurn",
          "weeklyBurn",
          "monthlyBurn",
        ].forEach(function (k) {
          c[k] += v[k];
        });
        return c;
      },
      {
        moduleKind: "Total Fuel Usage:",
        staticRate: 0,
        numOnline: 0,
        hourlyBurn: 0,
        weeklyBurn: 0,
        monthlyBurn: 0,
      }
    )
  );
  return results;
}

class FuelReport extends Component {
  render() {
    const columns = [
      {
        text: "Service Module",
        dataField: "moduleKind",
        sort: true,
        filter: textFilter(),
      },
      {
        text: "Static Rate",
        dataField: "staticRate",
      },
      {
        text: "Online amount",
        dataField: "numOnline",
        sort: true,
      },
      {
        text: "Hourly burn",
        dataField: "hourlyBurn",
        sort: true,
      },
      {
        text: "Weekly Burn",
        dataField: "weeklyBurn",
        sort: true,
      },
      {
        text: "Monthly Burn",
        dataField: "monthlyBurn",
        sort: true,
      },
    ];

    return (
      <div>
        <Container fluid>
          <Row>
            <Col>
              <h1>Fuelblock Usage Report</h1>
            </Col>
          </Row>
          <Row>
            <Col className="no-padding">
              <AsyncTable
                requestUrl={"citadels"}
                columnDefinition={columns}
                rowFilter={makeReport}
                keyField={"moduleKind"}
                sortKey={"monthlyBurn"}
                sortDirection={"desc"}
                fluid
              />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default FuelReport;
